import {
  createSettingsParams,
  SettingsParamType,
} from 'yoshi-flow-editor-runtime/tpa-settings';
import { appName } from '../../../.application.json';

export type ISettingsParams = {
  rcpText: SettingsParamType.Text;
  setByNewRCPSettings: SettingsParamType.Boolean;
};

export default createSettingsParams<ISettingsParams>({
  rcpText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => `${t('Rcp_Settings_Default_Rcp_Text')}`,
  },
  setByNewRCPSettings: {
    type: SettingsParamType.Boolean,
    getDefaultValue: ({ t }) => false,
  },
});
